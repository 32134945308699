'use client'

import { Button, Cell } from '@vinted/web-ui'

import { Screen } from 'constants/tracking/screens'
import { CREATE_BUNDLE_URL } from 'constants/routes'
import StartBundleButtonWrapper from 'components/StartBundleButtonWrapper'
import useTranslate from 'hooks/useTranslate'
import { UserBundleDiscountModel } from 'types/models'

type Props = {
  itemUserId: number
  bundleDiscount: UserBundleDiscountModel | null
  itemId?: number | null
  canBuyItem?: boolean
  testId?: string
}

const BundleEntryBanner = ({
  bundleDiscount,
  itemId = null,
  itemUserId,
  canBuyItem = false,
  testId,
}: Props) => {
  const translate = useTranslate('bundles.item')

  const bundleDiscountText = () => {
    if (!bundleDiscount?.isEnabled) return translate('discounts.not_available')

    const { discounts } = bundleDiscount

    const fractions = discounts.map(discount => Number(discount.fraction))
    const biggestFraction = Math.max(...fractions)

    return translate('discounts.available', {
      discount: (biggestFraction * 100).toFixed(0),
    })
  }

  const startNewBundleUrl = CREATE_BUNDLE_URL(itemUserId)

  return (
    <Cell
      title={translate('shop_title')}
      body={bundleDiscountText()}
      suffix={
        <StartBundleButtonWrapper
          url={startNewBundleUrl}
          itemId={canBuyItem ? itemId : null}
          screen={Screen.Item}
        >
          {({ onClick }) => (
            <Button
              text={translate('shop_action')}
              size={Button.Size.Medium}
              styling={Button.Styling.Filled}
              onClick={onClick}
              testId={testId ? `${testId}-shop-button` : undefined}
            />
          )}
        </StartBundleButtonWrapper>
      }
      testId={testId}
    />
  )
}

export default BundleEntryBanner
