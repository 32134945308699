import { useState, useEffect, useCallback } from 'react'

import { getBusinessAccountPolicies } from 'data/api'
import { transformBusinessAccountPoliciesDto } from 'data/transformers/business-account'
import { BusinessAccountPoliciesModel } from 'types/models'

function useFetchBusinessUserPolicies(businessAccountId?: number) {
  const [policies, setPolicies] = useState<BusinessAccountPoliciesModel>()

  const fetchBusinessAccountPolicies = useCallback(async () => {
    if (!businessAccountId) return
    const response = await getBusinessAccountPolicies({ businessAccountId })

    if ('errors' in response) return

    setPolicies(transformBusinessAccountPoliciesDto(response.terms))
  }, [businessAccountId])

  useEffect(() => {
    fetchBusinessAccountPolicies()
  }, [fetchBusinessAccountPolicies])

  return { policies }
}

export default useFetchBusinessUserPolicies
