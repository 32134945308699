import { Spacer } from '@vinted/web-ui'
import { FormattedDate, FormattedMessage } from 'react-intl'

import { FormattedRelativeTime } from 'components/@support'

type Props = {
  id?: string
  date: Date
  relativeDate?: boolean
  inline?: boolean
}

const FormattedDateMessage = ({ id, date, relativeDate = false, inline = false }: Props) => {
  const content = (
    <>
      {id && (
        <>
          <FormattedMessage id={id} />
          <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        </>
      )}
      {relativeDate ? (
        <FormattedRelativeTime value={+date} />
      ) : (
        <FormattedDate value={date} year="numeric" month="long" day="numeric" />
      )}
    </>
  )

  if (inline) {
    return <span>{content}</span>
  }

  return <div>{content}</div>
}

export default FormattedDateMessage
