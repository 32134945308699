export enum SellerFiltersType {
  Active = 'active',
  Reserved = 'reserved',
  Drafts = 'draft',
  Sold = 'sold',
  Hidden = 'hidden',
}

export enum SellerFiltersRespKeys {
  Active = 'Active',
  Reserved = 'Reserved',
  Drafts = 'Drafts',
  Sold = 'Sold',
  Hidden = 'Hidden',
  All = 'All',
}

export type TransformedSellerFilters = {
  [key in SellerFiltersRespKeys]: number
}
