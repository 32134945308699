const MIN_BUNDLE_ITEMS = 2

type Args = {
  canBundle: boolean
  totalItemCount: number
  isCurrentUserAuthenticated: boolean
  isViewingOwnProfile: boolean
  closetCountdownEndDate: string | undefined | null
  hasHateFromAnySide: boolean
}

export const isBundlingAvailable = ({
  canBundle,
  totalItemCount,
  isCurrentUserAuthenticated,
  isViewingOwnProfile,
  closetCountdownEndDate,
  hasHateFromAnySide,
}: Args) => {
  return (
    canBundle &&
    totalItemCount >= MIN_BUNDLE_ITEMS &&
    isCurrentUserAuthenticated &&
    !isViewingOwnProfile &&
    !hasHateFromAnySide &&
    !closetCountdownEndDate
  )
}
