'use client'

import { MouseEvent } from 'react'
import { Button } from '@vinted/web-ui'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import { followUserEvent } from '_libs/common/event-tracker/events'

import useFollowUser from './useFollowUser'

type Props = {
  userId: number
  isActive: boolean
  username?: string
  isFlat?: boolean
  size?: ComponentProps<typeof Button>['size']
  inline?: ComponentProps<typeof Button>['inline']
  onFollowToggle?: (payload: { isFollowEvent: boolean }, event: MouseEvent) => void
  testId?: string
}

const FollowButton = ({
  userId,
  username,
  isActive,
  isFlat,
  size,
  inline,
  onFollowToggle,
  testId,
}: Props) => {
  const translate = useTranslate('user.follow_button')
  const { track } = useTracking()

  const { isFollowed, toggleFollow } = useFollowUser({
    userId,
    isFollowed: isActive,
  })

  function handleClick(event: MouseEvent) {
    if (onFollowToggle) {
      onFollowToggle({ isFollowEvent: !isFollowed }, event)
    } else {
      track(followUserEvent({ userId, isFollowEvent: !isFollowed }))
    }

    toggleFollow()
  }

  function getButtonStyling() {
    if (isFlat) return Button.Styling.Flat
    if (isFollowed) return undefined

    return Button.Styling.Filled
  }

  function getText() {
    if (isFollowed) return translate('following')
    if (username) return translate('follow_username', { username })

    return translate('follow')
  }

  return (
    <Button
      size={size}
      styling={getButtonStyling()}
      onClick={handleClick}
      text={getText()}
      inline={inline}
      testId={testId}
    />
  )
}

export default FollowButton
