import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import useAbTest from 'hooks/useAbTest'
import useSession from 'hooks/useSession'
import { UserDto } from 'types/dtos'

type Props = {
  user: UserDto | undefined
  abTestName: string
  shouldExposeSelf?: boolean
}

const useSellerBadgeAbTest = ({ user, abTestName, shouldExposeSelf = true }: Props) => {
  const isFastReplierBadgeEnabled = useFeatureSwitch('web_fast_replier_badge')
  const currentUser = useSession().user

  const isViewingSelf = currentUser?.id === user?.id
  const bothUsersNotBusiness = user && !currentUser?.business && !user.business

  const abTest = useAbTest({
    abTestName,
    shouldTrackExpose:
      bothUsersNotBusiness && isFastReplierBadgeEnabled && (shouldExposeSelf || !isViewingSelf),
  })

  useAbTest({
    abTestName: `${abTestName}_aa_test`,
    shouldTrackExpose:
      bothUsersNotBusiness && isFastReplierBadgeEnabled && (shouldExposeSelf || !isViewingSelf),
  })

  return abTest
}

export default useSellerBadgeAbTest
