import { useEffect, useRef, useState } from 'react'

import { toggleUserFollow } from 'data/api'
import useAuthModal from '_libs/common/auth-modal/useAuthModal'
import useSession from 'hooks/useSession'

type Props = {
  userId: number
  isFollowed: boolean
}

const useFollowUser = ({ userId, isFollowed: initialIsFollowed }: Props) => {
  const isLoggedIn = Boolean(useSession().user)
  const [isFollowed, setIsFollowed] = useState(initialIsFollowed)
  const isLoading = useRef(false)
  const { openAuthModal } = useAuthModal()

  useEffect(() => {
    setIsFollowed(initialIsFollowed)
  }, [initialIsFollowed])

  async function toggleFollow() {
    if (!isLoggedIn) {
      openAuthModal()

      return
    }
    if (isLoading.current) return

    isLoading.current = true
    const response = await toggleUserFollow({ userId })
    isLoading.current = false

    if ('errors' in response) return

    setIsFollowed(response.is_followed)
  }

  return {
    isFollowed,
    toggleFollow,
  }
}

export default useFollowUser
