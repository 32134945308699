import { useState } from 'react'

import { calculateRemainingTime, formatTime, parseDateToSeconds } from '_libs/utils/date'
import { MS_PER_SECOND } from 'constants/date'

import useInterval from './useInterval'

type FormattedRemainingTime = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

const useClosetCountdown = (endDate: string) => {
  const endTimeInSeconds = parseDateToSeconds(endDate)
  const [formattedRemainingTime, setFormattedRemainingTime] = useState<FormattedRemainingTime>()
  const [isCountdownActive, setIsCountdownActive] = useState(!!endTimeInSeconds)

  useInterval(
    () => {
      if (!endTimeInSeconds) return

      const timeLeft = calculateRemainingTime(endTimeInSeconds)

      setIsCountdownActive(timeLeft > 0)
      setFormattedRemainingTime(formatTime(timeLeft))
    },
    MS_PER_SECOND,
    [endTimeInSeconds],
    true,
  )

  return { formattedRemainingTime, isCountdownActive }
}

export default useClosetCountdown
