import { useEffect, useRef } from 'react'

type IntervalFunction = () => void

function useInterval(
  callback: IntervalFunction,
  delay: number | null,
  dependencies?: Array<unknown>,
  leading = false,
) {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    if (!delay) return undefined
    if (leading) callbackRef.current()

    const interval = setInterval(callbackRef.current, delay)

    return () => clearInterval(interval)

    // It can't statically verify whether dependencies are correct with spread operator
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...(dependencies || [])])
}

export default useInterval
