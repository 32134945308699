export enum SellerBadgeType {
  ActiveLister = 'ACTIVE_LISTER',
  FastReplier = 'FAST_REPLIER',
}

export enum SellerBadgeTranslationKey {
  ActiveLister = 'active_lister',
  FrequentUploads = 'frequent_uploads',
  QuickReplier = 'quick_replier',
}
